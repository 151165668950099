* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #ECF0F1;
  background: black;
  /*background: #1B1212;*/
}

@font-face {
  font-family: 'LoraRegular';
  src: url('../fonts/Lora-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'LoraSemibold';
  src: url('../fonts/Lora-SemiBold.ttf') format('truetype');
}

body {
  font-family: 'LoraRegular', Arial, Helvetica, sans-serif;
  margin-left: 2%;
  margin-right: 2%;
}

.link {
  /*background: #1e1e1e;*/
  background: #1B1212;
}

.header {
  font-weight: bold;
  text-align: center;
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  margin-top: 1%;
}

.trini-flag {
  margin-left: 1%;
  height: 5%;
  width: 5%;
}

.header2 {
  text-align: center;
  margin-top: 1%;
}

.food-grid {
  display: grid;
  margin-bottom: 0.5%;
  gap: 0.5%;
  grid-template-columns: repeat(auto-fill, minmax(18%, 1fr));
  margin-top: 2%;
}

.food-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5%;
  margin-bottom: 20%;
  padding-bottom: 10%;
  padding-top: 10%;
  /*background: #1e1e1e;*/
  background: #1B1212;
  border-radius: 5%;
}

.food-image {
  height: 250px;
  width: 250px;
  border-radius: 5%;
}

.food-name {
  padding-left: 15%;
  padding-right: 15%;
  line-height: 24px;
  font-size: 1.2em;
  font-weight: bold;
  font-family: 'LoraSemibold';
  /*background: #1e1e1e;*/
  background: #1B1212;
}

.food-description {
  padding-left: 15%;
  padding-right: 15%;
  padding-bottom: 5%;
  line-height: 24px;
  white-space: pre-line;
}

footer {
  text-align: center;
}

.footer-description {
  margin-bottom: 0.5%;
  font-size: 1.2em;
}

/*CONTACT SECTION */

.contact-section {
    width: 550px;
    margin: auto;
    width: 50%;
    padding: 10px;
}

.contact-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 70px;
}

.contact-header {
    text-align: center;
    font-size: 2em;
    margin-bottom: 5px;
    color: white;
}

label {
    margin-bottom: 10px;
    color: white;
}

input {
    color: #fff;
    border: 0;
    background: #262626;
    width: 500px;
    height: 45px;
    margin-bottom: 15px;
    outline: none;
    border-radius: 6px;
    font-size: 18px;
}

.message-textarea {
    width: 500px;
    height: 150px;
    color: #fff;
    border: 0;
    background: #262626;
    outline: none;
    border-radius: 6px;
    font-size: 18px;
    margin-bottom: 25px;
    resize: none;
}

input[type="email"] {
    padding-left: 8px;
}

textarea[type="text"] {
    padding-left: 8px;
    padding-top: 10px;
}

/* CSS */
.button-1 {
    background-color: #D61355;
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    line-height: 20px;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 10px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: color 100ms;
    vertical-align: baseline;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 90px;
}

.button-2 {
    background-color: #D61355;
    border-radius: 8px;
    border-style: none;
    /*border-color: #1e1e1e;*/
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: 'LoraRegular';
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    line-height: 20px;
    list-style: none;
    margin: 0;
    margin-top: 5%;
    outline: none;
    padding: 10px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: color 100ms;
    vertical-align: baseline;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 120px;
}

.contact-succeed-message {
    text-align: center;
    margin-bottom: 1%;
}

/* PHONE AREA */
@media only screen and (max-width: 600px) {
  body {
    margin-left: 2%;
    margin-right: 2%;
  }

h1 {
  font-size: 1.4em;
}

  .header {
    margin-top: 4%;
  }

  .header2 {
    margin-top: 5%;
    font-size: 0.75em;
  }

  .trini-flag {
    margin-left: 3%;
    height: 20%;
    width: 20%;
  }

  .food-grid {
    grid-template-columns: repeat(auto-fit, minmax(80%, 1fr));
    margin-top: 10%;
    margin-bottom: 150%;
    padding-bottom: 10%;
  }

  .food-item {
    gap: 3%;
    margin-bottom: 5%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .food-image {
    margin-bottom: 2%;
  }

  .food-name {
    text-align: center;
  }

  .food-description {
    text-align: center;
  }

  .footer-description {
    margin-bottom: 2%;
    padding-bottom: 1%;
  }

    /* CONTACT */

    .contact-section {
        width: 550px;
        margin: auto;
        width: 50%;
        padding: 10px;
    }

    .contact-flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
        max-width: 100%;
    }

    .contact-header {
        text-align: center;
        font-size: 1.5em;
        margin-bottom: 5px;
        color: white;
        width: 100%;
    }

    label {
        margin-bottom: 10px;
        color: white;
    }

    input {
        color: #fff;
        border: 0;
        background: #262626;
        width: 150%;
        height: 45px;
        margin-bottom: 15px;
        outline: none;
        border-radius: 6px;
        font-size: 16px;
    }

    .message-textarea {
        width: 150%;
        height: 150px;
        color: #fff;
        border: 0;
        background: #262626;
        outline: none;
        border-radius: 6px;
        font-size: 18px;
        margin-bottom: 25px;
        resize: none;
    }

    input[type="email"] {
        padding-left: 8px;
    }

    textarea[type="text"] {
        padding-left: 8px;
        padding-top: 10px;
    }

.button-2 {
  margin-top: 10%;
  width: 150px;
  height: 60px;
}

}

/* Tablet Area */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1200px) {

  body {
    margin-left: 2%;
    margin-right: 2%;
  }

  h1 {
    font-size: 2.5em;
  }

  .header {
    margin-top: 4%;
    font-size: 1em;
  }

  .trini-flag {
    margin-left: 3%;
    height: 20%;
    width: 20%;
  }

  .food-grid {
    grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
    margin-top: 10%;
    margin-bottom: 5%;
    padding-bottom: 10%;
  }

  .food-item {
    gap: 3%;
    margin-bottom: 15%;
    font-size: 1.5em;
  }

  .footer-description {
    margin-bottom: 1%;
    padding-bottom: 1%;
    font-size: 1.5em;
  }

  /* CONTACT */

    .contact-section {
        width: 550px;
        margin: auto;
        width: 50%;
        padding: 10px;
    }

    .contact-flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 70px;
        max-width: 100%;
    }

    .contact-header {
        text-align: center;
        font-size: 2em;
        margin-bottom: 30px;
        color: white;
        width: 100%;
    }

    label {
        margin-bottom: 10px;
        color: white;
    }

    input {
        color: #fff;
        border: 0;
        background: #262626;
        width: 150%;
        height: 45px;
        margin-bottom: 15px;
        outline: none;
        border-radius: 6px;
        font-size: 1.4em;
    }

    .message-textarea {
        width: 150%;
        height: 150px;
        color: #fff;
        border: 0;
        background: #262626;
        outline: none;
        border-radius: 6px;
        font-size: 1.9em;
        margin-bottom: 25px;
        resize: none;
    }

    input[type="email"] {
        padding-left: 8px;
    }

    textarea[type="text"] {
        padding-left: 8px;
        padding-top: 10px;
    }
}

