* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #ECF0F1;
}

@font-face {
  font-family: 'LoraRegular';
  src: url('../fonts/Lora-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'LoraSemibold';
  src: url('../fonts/Lora-SemiBold.ttf') format('truetype');
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5%;
  margin-top: 10%;
  /*background: #1B1212;*/
}

.image {
  width: 400px;
  height: 400px;
  border-radius: 5%;
}

.text-side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 10%;
  padding-right: 20%;
}

.back-button {
  height: 32px;
  width: 32px;
}

.all-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:flex-start;
}

.name {
  font-family: 'LoraSemibold', Arial, Helvetica, sans-serif;
  font-size: 2em;
  margin-bottom: 5%;
}

.options {
  font-family: 'LoraRegular', Arial, Helvetica, sans-serif;
  font-size: 1.4em;
  margin-bottom: 5%;
}

.pricing {
  font-family: 'LoraRegular', Arial, Helvetica, sans-serif;
  font-size: 1.4em;
}

/* MOBILE DESIGN */
@media only screen and (max-width: 600px) {
  .image {
    width: 250px;
    height: 250px;
    margin-top: 10%;
  }

  .back-button {
    margin-top: 40%;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #1B1212;
  }

  .all-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #1B1212;
  }

  .name {
    background: #1B1212;
  }

  .text-side {
    background: #1B1212;
    justify-content: center;
    align-items: center;
    padding-right: 0;
  }

  .options {
    background: #1B1212;
  }

  .pricing {
    background: #1B1212;

  }

  .back-button-div {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}